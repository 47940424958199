var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"hold-transition"},[_c('div',{staticClass:"modal fade",attrs:{"id":"modal-form-detalle-tepsolicitud"}},[(_vm.data_solicitud)?_c('div',{staticClass:"modal-dialog modal-xl"},[_c('div',{staticClass:"modal-content"},[_vm._m(0),_c('div',{staticClass:"modal-body p-0 pt-2"},[_c('ul',{staticClass:"nav nav-tabs",attrs:{"id":"myTab","role":"tablist"}},[_vm._m(1),_c('li',{staticClass:"nav-item"},[(Object.keys(_vm.data_solicitud.autorizacion).length > 0)?_c('a',{staticClass:"nav-link",attrs:{"data-toggle":"tab","href":"#Autorizacion"}},[_vm._v("Autorizaciones ")]):_vm._e()])]),_c('div',{staticClass:"tab-content p-3",attrs:{"id":"myTabContent"}},[_c('div',{staticClass:"tab-pane fade active show",attrs:{"id":"Solicitud"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header bg-light"},[_c('h5',{staticClass:"card-title"},[_vm._v("Información")]),_c('div',{staticClass:"card-tools"},[_c('span',{staticClass:"badge",class:_vm.data_solicitud.estado == 1
                            ? 'bg-warning'
                            : _vm.data_solicitud.estado == 2
                            ? 'bg-success'
                            : _vm.data_solicitud.estado == 3
                            ? 'bg-danger'
                            : _vm.data_solicitud.estado == 4
                            ? 'bg-info'
                            : 'bg-secondary'},[_vm._v(" "+_vm._s(_vm.data_solicitud.nEstado)+" ")])])]),_c('div',{staticClass:"card-body text-muted"},[_c('ul',{staticClass:"fa-ul mb-0"},[_c('li',[_vm._m(2),_c('strong',[_vm._v("Solicitud - "+_vm._s(_vm.data_solicitud.id))]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-5"},[_vm._v("Tipo Solicitud:")]),_c('div',{staticClass:"col-md-7 mb-1"},[_c('strong',[_vm._v(_vm._s(_vm.data_solicitud ? _vm.data_solicitud.tipoSolicitud : ""))])])]),(_vm.data_solicitud.tipoEmergencia)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-5"},[_vm._v("Tipo Emergencia:")]),_c('div',{staticClass:"col-md-7 mb-1"},[(_vm.data_solicitud.archivo_emergencia)?_c('a',{staticClass:"text-muted mr-2",attrs:{"href":this.$parent.uri_docs + _vm.data_solicitud.archivo_emergencia,"target":"_blank"}},[_c('i',{staticClass:"fas fa-download"})]):_vm._e(),_c('span',{staticClass:"badge",class:_vm.data_solicitud.tipo_emergencia == 1
                                    ? 'bg-info'
                                    : _vm.data_solicitud.tipo_emergencia == 2
                                    ? 'bg-maroon'
                                    : 'bg-secondary'},[_vm._v(" "+_vm._s(_vm.data_solicitud.tipoEmergencia)+" ")])])]):_vm._e(),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-5"},[_vm._v("Fecha:")]),_c('div',{staticClass:"col-md-7 mb-1"},[_c('strong',[_vm._v(_vm._s(_vm.data_solicitud.fecha))])])]),(_vm.data_solicitud.bloque)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-5"},[_vm._v("Bloque:")]),_c('div',{staticClass:"col-md-7 mb-1"},[_c('strong',[_vm._v(_vm._s(_vm.data_solicitud.bloque.nombre))])])]):_vm._e(),(_vm.data_solicitud.sitio)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-5"},[_vm._v("Sitio:")]),_c('div',{staticClass:"col-md-7 mb-1"},[_c('strong',[_vm._v(_vm._s(_vm.data_solicitud.sitio.nombre))])])]):_vm._e(),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-5"},[_vm._v("Usuario Solicitante:")]),_c('div',{staticClass:"col-md-7 mb-1"},[_c('strong',[_vm._v(_vm._s(_vm.data_solicitud.user ? _vm.data_solicitud.user.name : ""))])])]),(_vm.data_solicitud.gerente_area)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-5"},[_vm._v("Gerente Área:")]),_c('div',{staticClass:"col-md-7 mb-1"},[_c('strong',[_vm._v(_vm._s(_vm.data_solicitud.gerente_area.nombres))])])]):_vm._e(),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-5"},[_vm._v("Presupuesto Verificado:")]),_c('div',{staticClass:"col-md-7 mb-1"},[_c('i',{staticClass:"fa",class:_vm.data_solicitud.presupuesto_verificado ? 
                                  'fa-check text-success':
                                  'fa-times-circle text-danger'})])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-5"},[_vm._v("Estado:")]),_c('div',{staticClass:"col-md-7 mb-1"},[_c('span',{staticClass:"badge",class:_vm.data_solicitud.estado == 1 ? 'bg-warning' :
                                  _vm.data_solicitud.estado == 2 ? 'bg-success' : 
                                  _vm.data_solicitud.estado == 3 ? 'bg-danger' : 
                                  _vm.data_solicitud.estado == 4 ? 'bg-lime' : 
                                  'bg-secondary'},[_vm._v(" "+_vm._s(_vm.data_solicitud.nEstado)+" ")])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-5"},[_vm._v("Justificación:")]),_c('div',{staticClass:"col-md-7 mb-1"},[_c('small',[_vm._v(_vm._s(_vm.data_solicitud.justificacion))])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-5"},[_vm._v("Observaciones:")]),_c('div',{staticClass:"col-md-7"},[_c('small',[_vm._v(_vm._s(_vm.data_solicitud.observaciones))])])])])])])])]),_c('div',{staticClass:"col-md-6"},[(Object.keys(_vm.data_solicitud.solicitudes_rutas).length > 0)?_c('div',{staticClass:"card"},[_vm._m(3),_c('div',{staticClass:"card-body p-0"},[_c('table',{staticClass:"table table-bordered table-striped table-hover table-sm mb-0",staticStyle:{"font-size":"0.85em"}},[_vm._m(4),_c('tbody',_vm._l((_vm.data_solicitud.solicitudes_rutas),function(ruta){return _c('tr',{key:ruta.id},[_c('td',{staticClass:"text-center"},[_c('span',{staticClass:"badge",class:ruta.tipo_sitio == 1
                                    ? 'bg-info'
                                    : ruta.tipo_sitio == 2
                                    ? 'bg-navy'
                                    : 'bg-secondary'},[_vm._v(" "+_vm._s(ruta.nTipoSitio)+" ")])]),_c('td',[_vm._v(_vm._s(ruta.nombre))]),_c('td',[_vm._v(_vm._s(ruta.direccion))]),_c('td',[_vm._v(_vm._s(ruta.nombre_contacto))])])}),0)])])]):_vm._e(),(Object.keys(_vm.data_solicitud.solicitudes_presupuestos).length > 0)?_c('div',{staticClass:"card"},[_vm._m(5),_c('div',{staticClass:"card-body p-0"},[_c('table',{staticClass:"table table-bordered table-striped table-hover table-sm mb-0",staticStyle:{"font-size":"0.85em"}},[_vm._m(6),_c('tbody',_vm._l((_vm.data_solicitud.solicitudes_presupuestos),function(presup){return _c('tr',{key:presup.id},[_c('td',[_vm._v(_vm._s(presup.nArea))]),_c('td',{staticClass:"text-center"},[_c('span',{staticClass:"badge",class:presup.tipo_presupuesto == 1
                                    ? 'bg-info'
                                    : presup.tipo_presupuesto == 2
                                    ? 'bg-navy'
                                    : 'bg-secondary'},[_vm._v(" "+_vm._s(presup.tipoPresupuesto)+" ")])]),_c('td',{staticClass:"text-right"},[_vm._v(_vm._s(presup.porcentaje)+"%")]),_c('td',[_vm._v(_vm._s(presup.responsable ? presup.responsable.name : ""))])])}),0)])])]):_vm._e()]),_c('div',{staticClass:"col-md-12"},[(Object.keys(_vm.data_solicitud.det_solicitudes).length > 0)?_c('div',{staticClass:"card"},[_vm._m(7),_c('div',{staticClass:"card-body p-0"},[_c('table',{staticClass:"table table-bordered table-striped table-hover table-sm mb-0",staticStyle:{"font-size":"0.85em"}},[_vm._m(8),_c('tbody',_vm._l((_vm.data_solicitud.det_solicitudes),function(det){return _c('tr',{key:det.id,staticClass:"text-center"},[_c('td',[_c('span',{staticClass:"badge",class:det.tipo_servicio == 1
                                    ? 'bg-info'
                                    : det.tipo_servicio == 2
                                    ? 'bg-navy'
                                    : 'bg-secondary'},[_vm._v(" "+_vm._s(det.tipoServicio)+" ")])]),_c('td',{staticClass:"text-left"},[_vm._v(_vm._s(det.tipo_servicio == 1 ? det.tipo_vehiculo.nombre : det.nTipoEquipo))]),_c('td',[_vm._v(_vm._s(det.cantidad_vh_equi))]),_c('td',[_vm._v(_vm._s(det.tiempo_serv))]),_c('td',{staticClass:"text-left text-nowrap"},[_vm._v(_vm._s(det.descripcion_material))]),_c('td',[_vm._v(_vm._s(det.cantidad_material))]),_c('td',[_vm._v(_vm._s(`${det.largo} x ${det.ancho} x ${det.alto}`))]),_c('td',[_vm._v(_vm._s(det.peso))]),_c('td',[_vm._v(_vm._s(det.tipo_servicio == 2 ? det.radio_izaje : ""))]),_c('td',{staticClass:"text-left"},[_c('small',[_vm._v(_vm._s(det.observaciones))])])])}),0)])])]):_vm._e()])])]),(Object.keys(_vm.data_solicitud.autorizacion).length > 0)?_c('div',{staticClass:"tab-pane",attrs:{"id":"Autorizacion"}},[_c('table',{staticClass:"table table-bordered table-striped table-hover table-sm mb-0",staticStyle:{"font-size":"0.85em"}},[_vm._m(9),_c('tbody',_vm._l((_vm.data_solicitud.autorizacion),function(aut){return _c('tr',{key:aut.id},[_c('td',[_vm._v(_vm._s(aut.tipoAutorizacion))]),_c('td',[_vm._v(_vm._s(aut.user_autoriza ? aut.user_autoriza.name : ""))]),_c('td',[_vm._v(_vm._s(aut.fecha_autorizacion))]),_c('td',{staticClass:"text-center"},[_c('span',{staticClass:"badge",class:aut.estado == 1
                            ? 'bg-info'
                            : aut.estado == 2
                            ? 'bg-success'
                            : aut.estado == 3
                            ? 'bg-danger'
                            : 'bg-secondary'},[_vm._v(" "+_vm._s(aut.nEstado)+" ")])])])}),0)])]):_vm._e()])])])]):_vm._e()])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modal-header bg-frontera-top-left"},[_c('h4',{staticClass:"modal-title text-white"},[_vm._v("Detalle Solicitud")]),_c('button',{staticClass:"close text-white",attrs:{"type":"button","data-dismiss":"modal","aria-label":"Close","id":"close-modal"}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link active",attrs:{"data-toggle":"tab","href":"#Solicitud"}},[_vm._v("Solicitud")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"fa-li"},[_c('i',{staticClass:"fas fa-lg fa-chalkboard-teacher"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-header bg-light"},[_c('h5',{staticClass:"card-title"},[_vm._v("Rutas")]),_c('div',{staticClass:"card-tools"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',[_vm._v("Tipo Sitio")]),_c('th',[_vm._v("Nombre")]),_c('th',[_vm._v("Direccion")]),_c('th',[_vm._v("Contacto")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-header bg-light"},[_c('h5',{staticClass:"card-title"},[_vm._v("Presupuestos")]),_c('div',{staticClass:"card-tools"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',[_vm._v("Área")]),_c('th',[_vm._v("Tipo Presupuesto")]),_c('th',[_vm._v("%")]),_c('th',[_vm._v("Responsable")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-header bg-light"},[_c('h5',{staticClass:"card-title"},[_vm._v("Detalles")]),_c('div',{staticClass:"card-tools"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',[_vm._v("Tipo Servicio")]),_c('th',[_vm._v("Tipo Vehículo / Equipo")]),_c('th',[_vm._v("Cant. Vehículos")]),_c('th',[_vm._v("Tiempo Requerido "),_c('small',[_vm._v("(días)")])]),_c('th',[_vm._v("Descripción")]),_c('th',[_vm._v("Cantidad Material")]),_c('th',[_vm._v("Largo x Ancho x Alto "),_c('small',[_vm._v("(mts)")])]),_c('th',[_vm._v("Peso "),_c('small',[_vm._v("(Kg)")])]),_c('th',[_vm._v("Radio de izaje "),_c('small',[_vm._v("(mts)")])]),_c('th',[_vm._v("Observaciones")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',[_vm._v("Tipo")]),_c('th',[_vm._v("Funcionario")]),_c('th',[_vm._v("Fecha Autorizo")]),_c('th',[_vm._v("Estado")])])])
}]

export { render, staticRenderFns }