<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Consulta Solicitudes Internas</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Carga Seca</li>
                  <li class="breadcrumb-item active">Consulta</li>
                  <li class="breadcrumb-item active">
                    Solicitudes de Transporte Interno
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card">
                <div class="card-header pt-2 pb-2"
                    v-if="$store.getters.can('cs.solicitudesInterno.exportExcel')"
                >
                <div class="row">
                  <div class="col-md-12">
                    <div class="btn-group float-right">
                      <!-- SE LLAMAN BOTONES ACA -->
                      <button
                        type="button"
                        class="btn btn-success"
                        data-toggle="modal"
                        data-target="#modal-form-export"
                        @click="$refs.CsSolicitudExport.resetFiltros()"
                      >
                        <i class="far fa-file-excel"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-body p-0 table-responsive">
                <table class="table table-bordered table-striped table-hover table-sm">
                  <thead>
                    <tr>
                      <th></th>
                      <th>
                        #<input v-model="filtros.id"
                          type="number"
                          class="form-control form-control-sm"
                          @keyup="getIndex()"
                        />
                      </th>
                      <th>
                        Tipo Solicitud
                        <select
                          class="form-control form-control-sm"
                          v-model="filtros.tipo_solicitud"
                          @change="getIndex()"
                        >
                          <option value="">Seleccione...</option>
                          <option
                            v-for="tipo_solicitud in listasForms.tipo_solicitudes"
                            :key="tipo_solicitud.numeracion"
                            :value="tipo_solicitud.numeracion"
                          >
                            {{ tipo_solicitud.descripcion }}
                          </option>
                        </select>
                      </th>
                      <th>
                        Tipo Emergencia
                        <select
                          class="form-control form-control-sm"
                          v-model="filtros.tipo_emergencia"
                          @change="getIndex()"
                        >
                          <option value="">Seleccione...</option>
                          <option
                            v-for="tipo_emergencia in listasForms.tipo_emergencias"
                            :key="tipo_emergencia.numeracion"
                            :value="tipo_emergencia.numeracion"
                          >
                            {{ tipo_emergencia.descripcion }}
                          </option>
                        </select>
                      </th>
                      <th>
                        Fecha
                        <input v-model="filtros.fecha"
                          type="date"
                          class="form-control form-control-sm"
                          @change="getIndex()"
                        />
                      </th>
                      <th>
                        Bloque
                        <input v-model="filtros.bloque"
                          type="text"
                          class="form-control form-control-sm"
                          @keyup="getIndex()"
                        />
                      </th>
                      <th>
                        Sitio
                        <input v-model="filtros.sitio"
                          type="text"
                          class="form-control form-control-sm"
                          @keyup="getIndex()"
                        />
                      </th>
                      <th>
                        Usuario Solicita<input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="filtros.usuario"
                          @keyup="getIndex()"
                        />
                      </th>
                      <th>
                        Estado
                        <select
                          class="form-control form-control-sm"
                          v-model="filtros.estado"
                          @change="getIndex()"
                        >
                          <option value="">Seleccione...</option>
                          <option
                            v-for="estado in listasForms.estados"
                            :key="estado.numeracion"
                            :value="estado.numeracion"
                          >
                            {{ estado.descripcion }}
                          </option>
                        </select>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in solicitudes.data" :key="item.id">
                      <td>
                        <div class="btn-group dropright">
                          <button type="button"
                            class="btn btn-dark btn-sm"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                            :disabled="
                                !$store.getters.can('cs.solicitudesInterno.cancelarSolicitud') ||
                                (item.estado == 3 || item.estado == 4)
                            "
                          >
                            <i class="fas fa-align-justify"></i>
                          </button>
                          <div class="dropdown-menu">
                            <a class="dropdown-item"
                              style="cursor: pointer"
                              type="button"
                              v-if="
                                $store.getters.can('cs.solicitudesInterno.cancelarSolicitud') &&
                                (item.estado == 1 || item.estado == 2)
                              "
                              @click="cancelarSolicitud(item)"
                            >
                              <i class="fas fa-backspace"></i>
                              Cancelar Solicitud
                            </a>
                          </div>
                        </div>
                      </td>
                      <td class="text-center">
                        <button
                          type="button"
                          class="dropdown-item"
                          style="cursor: pointer"
                          data-toggle="modal"
                          data-target="#modal-form-detalle-tepsolicitud"
                          v-if="$store.getters.can('cs.solicitudesInterno.index')"
                          @click="$refs.CsSolicitudDetalle.llenar_modal(item)"
                        >
                          {{ item.id }}
                        </button>
                      </td>
                      <td class="text-center">
                        <span
                          class="badge"
                          :class="
                            item.tipo_solicitud == 1
                              ? 'bg-navy'
                              : item.tipo_solicitud == 2
                              ? 'bg-warning'
                              : 'bg-secondary'
                          "
                        >
                            {{ item.tipoSolicitud ? item.tipoSolicitud : "Sin establecer" }}
                        </span>
                      </td>
                      <td class="text-center text-nowrap">
                        <span
                          class="badge"
                          :class="
                            item.tipo_emergencia == 1
                              ? 'bg-info'
                              : item.tipo_emergencia == 2
                              ? 'bg-maroon'
                              : 'bg-secondary'
                          "
                        >
                            {{ item.tipoEmergencia ? item.tipoEmergencia : "Sin establecer" }}
                        </span>
                        <a v-if="item.archivo_emergencia"
                            :href="uri_docs + item.archivo_emergencia" target="_blank" class="text-muted ml-2">
                            <i class="fas fa-download"></i>
                        </a>
                      </td>
                      <td class="text-center">{{ item.fecha }}</td>
                      <td class="text-nowrap">{{ item.bloque ? item.bloque.nombre : "" }}</td>
                      <td class="text-nowrap">{{ item.sitio ? item.sitio.nombre : "" }}</td>
                      <td class="text-nowrap">
                        {{ item.user ? item.user.name : ""  }}
                      </td>
                      <td class="text-center">
                        <span
                          class="badge"
                          :class="
                            item.estado == 1
                              ? 'bg-warning'
                              : item.estado == 2
                              ? 'bg-success'
                              : item.estado == 3
                              ? 'bg-danger'
                              : item.estado == 4
                              ? 'bg-info'
                              : 'bg-secondary'
                          "
                        >
                          {{ item.nEstado }}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="card-footer">
                <div class="float-left" v-if="solicitudes.total">
                  <p>
                    Mostrando del <b>{{ solicitudes.from }}</b> al
                    <b>{{ solicitudes.to }}</b> de un total:
                    <b>{{ solicitudes.total }}</b> Registros
                  </p>
                </div>
                <div class="float-left" v-else>
                  <p>
                    <span class="badge badge-danger">
                      No hay registros para mostrar
                    </span>
                  </p>
                </div>
                <pagination
                  :data="solicitudes"
                  @pagination-change-page="getIndex"
                  :limit="10"
                  class="float-right"
                ></pagination>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
    <CsSolicitudExport ref="CsSolicitudExport" />
    <CsSolicitudDetalle ref="CsSolicitudDetalle" />
  </div>
</template>
  
  <script>
import axios from "axios";
import Loading from "../../../../components/Loading";
import pagination from "laravel-vue-pagination";
import CsSolicitudExport from "./CsSolicitudExport";
import CsSolicitudDetalle from "./CsSolicitudDetalle";

export default {
  name: "CsSolicitudIndex",
  components: {
    Loading,
    pagination,
    CsSolicitudExport,
    CsSolicitudDetalle,
  },
  data() {
    return {
      cargando: false,
      solicitudes: {},
      filtros: {
        id: null,
        tipo_solicitud: null,
        fecha: null,
        bloque: null,
        sitio: null,
        usuario: null,
        tipo_emergencia: null,
        estado: null,
      },
      listasForms: {
        tipo_solicitudes: [],
        tipo_emergencias: [],
        estados: [],
      },
      uri_docs: process.env.VUE_APP_AWS_BUCKET_URL,
    };
  },
  methods: {
    getIndex(page = 1) {
      this.cargando = true;
      axios
        .get("/api/cs/SolicitudesInternas?page=" + page, {
          params: this.filtros,
        })
        .then((response) => {
          this.solicitudes = response.data;
          this.cargando = false;
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrió un error: " + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.cargando = false;
        });
    },

    getTipoSolicitudes() {
      axios.get("/api/lista/127").then((response) => {
        this.listasForms.tipo_solicitudes = response.data;
      });
    },

    getTipoEmergencia() {
      axios.get("/api/lista/155").then((response) => {
        this.listasForms.tipo_emergencias = response.data;
      });
    },

    getEstados() {
      axios.get("/api/lista/135").then((response) => {
        this.listasForms.estados = response.data;
      });
    },

    cancelarSolicitud(item) {
      if (this.$store.getters.can("cs.solicitudesInterno.cancelarSolicitud")) {
        this.$swal({
          icon: "warning",
          title: `Está seguro de cancelar la solicitud interna #${ item.id }?`,
          text: "No podrá revertir esta acción",
          showCancelButton: true,
          confirmButtonText: "Si, Cancelar!",
        }).then((result) => {
          if (result.value) {
            this.cargando = true;
            const me = this;
            axios
              .put("/api/cs/SolicitudesInternas/cancelarSolicitud", {
                solicitud_id: item.id,
              })
              .then(() => {
                me.cargando = false;
                me.getIndex(me.solicitudes.current_page);
                me.$swal({
                  icon: "success",
                  title: `Turno cambiado a nacional exitosamente.`,
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                  timerProgressBar: true,
                });
              })
              .catch(function(error) {
                me.cargando = false;
                me.$swal({
                  icon: "error",
                  title: "Ocurrió un error: " + error,
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                  timerProgressBar: true,
                });
              });
          }
        });
      }
    },
  },

  mounted() {
    this.getIndex();
    this.getTipoSolicitudes();
    this.getTipoEmergencia();
    this.getEstados();
  },
};
</script>
  